/* eslint-disable indent */
import axios from "axios";
import queryString from "query-string";
import { ZodSchema } from "zod";

export type Pagination = {
    page: number;
    pageSize: number;
};

export class ValidationError extends Error {
    fields?: any[];

    super(message: string, fields?: any[]) {
        this.message = message;
        this.fields = fields;
    }
}

export const requireEnvVar = (key: string) => {
    const value = process.env[key];
    if (!value) {
        throw new Error(`Missing required env var: ${key}`);
    }
    return value;
};

const baseUrl = requireEnvVar("REACT_APP_API_URL");

export const makeRequest = async ({
    method,
    path,
    queryParams,
    data,
    authToken,
}: {
    method: "get" | "post" | "patch" | "delete";
    path: string;
    queryParams?: Record<string, any>;
    data?: Record<string, any>;
    authToken?: string;
}) => {
    const headers = {
        "Content-Type": "application/json",
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    };

    const url = `${baseUrl}${path}${queryParams ? `?${queryString.stringify(queryParams)}` : ""}`;

    try {
        const response = await axios({
            headers,
            method,
            url,
            data,
        });

        return {
            status: response?.status,
            data: response?.data,
            headers: response?.headers,
        };
    } catch (error: any) {
        const response: Record<string, any> | undefined = error?.response;
        const errorJson = response?.data?.error;
        const message = errorJson?.message;

        if (response) {
            if (response.status >= 400 && response.status < 500) {
                throw new ValidationError(message, response.data);
            }
            if (!(response.status >= 200 && response.status < 300)) {
                throw new Error(message);
            }
        }

        throw error;
    }
};

export const fetchItems = async ({
    pageParam,
    queryKey,
    serviceFunction,
    itemsPerPage = 10,
}: {
    pageParam?: { hasNextPage: boolean; startAt?: number };
    queryKey: any;
    serviceFunction: (startAt: number, itemsPerPage: number, searchQuery?: string) => Promise<any>;
    itemsPerPage?: number;
}) => {
    const [_query, searchQuery] = queryKey;
    const { hasNextPage, startAt = 0 } = pageParam ?? { hasNextPage: true, startAt: 0 };

    try {
        if (!hasNextPage) {
            return { pages: [], total: 0, hasNextPage: false };
        }

        const response = await serviceFunction(startAt, itemsPerPage, searchQuery);

        if (!response) {
            return { pages: [], total: 0, hasNextPage: false };
        }

        const { total, pageItems } = response.data || response;
        return {
            pages: pageItems || [],
            hasNextPage,
            startAt: startAt + (pageItems?.length || 0),
            total,
        };
    } catch (error) {
        console.error(`Could not fetch items: ${error}`);
        throw error;
    }
};

export const parseSchema = <D>(schema: ZodSchema<D>, data: any) => {
    try {
        return schema.parse(data);
    } catch (e: any) {
        const issues = e?.issues;
        const parsedIssues = issues
            ? issues.map((issue: any) => ({
                  message: issue.message,
                  path: issue.path.join("."),
                  code: issue.code,
              }))
            : undefined;
        throw new Error(
            `Failed to parse schema. ${parsedIssues ? `Parsing issues: ${JSON.stringify(parsedIssues, null, 4)}` : ""}`,
        );
    }
};
