import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getCompanies } from "../actions/get-companies";
import { CompanyFilters } from "../components/modals/company-filters-modal";

type Params = {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
    shouldPaginate?: boolean;
};

const getCompaniesQueryKey = ({ authToken, filters, adHocFilters, shouldPaginate }: Params) => {
    return ["companies", { authToken, filters, adHocFilters, shouldPaginate }];
};

export const useCompanies = ({ authToken, filters, adHocFilters, shouldPaginate = false }: Params) => {
    const query = useQuery({
        queryKey: getCompaniesQueryKey({ authToken, filters, adHocFilters }),
        queryFn: async () => {
            const { companies } = await getCompanies({ authToken, filters, adHocFilters });
            return companies;
        },
    });

    const paginatedQuery = useInfiniteQuery(
        getCompaniesQueryKey({ authToken, filters, adHocFilters, shouldPaginate }),
        async ({ pageParam }) => {
            const response = await getCompanies({
                authToken,
                filters: {
                    ...filters,
                    offset: pageParam?.startAt || 0,
                },
                adHocFilters,
            });

            return {
                companies: response.companies,
                hasNextPage: pageParam?.hasNextPage,
                startAt: response.companies.length + (pageParam?.startAt || 0),
                total: response.totalCount,
            };
        },
        {
            refetchOnMount: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                const hasNextPage = lastPage?.hasNextPage || null;
                return { hasNextPage, startAt: lastPage?.startAt || 0 };
            },
        },
    );
    return shouldPaginate ? paginatedQuery : query;
};
