import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { sumBy } from "lodash";
import { HTMLAttributes, ReactNode, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { Currency } from "../billing/components";
import {
    affiliateCommissionMultiplier,
    getEstimatedPayoutFromData,
} from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getProcessflowProgresses } from "../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getUsers } from "../jason-proof-of-concept/users/actions/get-users";
import { getDataFromProgressData } from "../jason-proof-of-concept/wizard/wizard-container";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { TBody, THead, Table, Td, Th, Tr } from "../layout/table";
import ButtonNeoGen from "../layout/button-neogen";
import { getCompanies } from "../companies/actions/get-companies";
import { CompanyStatus } from "../companies/domain/company";
import { timeAgo } from "../sections/users/cards/calls-list";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { sortUsers } from "../sections/utilities/sortUsers";
import { Select } from "../layout/form/select-input";

export const Section = ({
    children,
    className,
    style,
}: {
    children: ReactNode;
    className?: string;
    style?: HTMLAttributes<HTMLDivElement>["style"];
}) => {
    return (
        <div
            style={style}
            className={`bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow p-5 ${
                className || ""
            }`}
        >
            {children}
        </div>
    );
};

export const Stat = ({ title, value }: { title: ReactNode; value: ReactNode }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="text-sm font-medium text-gray-400">{title}</div>
            <div className="text-2xl font-semibold text-gray-500 dark:text-green-500">{value}</div>
        </div>
    );
};

export const AffiliateBillingPage = () => {
    const now = useMemo(() => new Date(), []);
    const [user] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const navigate = useNavigate();
    const { search } = useLocation();
    const queryParamUserId = useMemo(() => new URLSearchParams(search), [search]);
    const userId = queryParamUserId.get("userId");
    const [affiliateUserId, setAffiliateUserId] = useState(userId);

    const query = useQuery(["affiliate-billing-data", { authToken, affiliateUserId }], async () => {
        const users = await getUsers({
            authToken,
            filters: {
                where: {
                    affiliateUserId,
                },
            },
        });
        const userIds = users.map((user) => user.id);
        const { companies } = await getCompanies({ authToken, filters: { where: { ownedById: { inq: userIds } } } });
        const progresses = await getProcessflowProgresses({ authToken, filters: { userIds, group: 7 } });
        return { users, progresses, companies };
    });

    const affiliateUsersQuery = useQuery(["affiliates", { authToken }], async () => {
        const users = await getUsers({
            authToken,
            filters: {
                where: {
                    role: "Affiliate",
                },
            },
        });
        return users;
    });

    const users = useMemo(() => query.data?.users || [], [query.data?.users]);
    const processflowProgresses = useMemo(() => query.data?.progresses || [], [query.data?.progresses]);
    const companies = useMemo(() => query.data?.companies || [], [query.data?.companies]);

    const rows = useMemo(
        () =>
            users.map((user) => {
                const processflowProgress = processflowProgresses.find((p) => p.userId === user.id);
                const dealValue = processflowProgress?.data
                    ? getEstimatedPayoutFromData({
                          data: getDataFromProgressData(processflowProgress.data as any),
                      })
                    : 0;
                const estimatedCommission = dealValue * affiliateCommissionMultiplier;
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                const company = companies.find((c) => c.ownedById === user.id);
                return {
                    user,
                    processflowProgress: processflowProgress,
                    progressPercentage,
                    lastActive: subDays(now, 2),
                    dealValue,
                    estimatedCommission,
                    company,
                };
            }),
        [companies, now, processflowProgresses, users],
    );

    const deaValueInProgress = useMemo(() => sumBy(rows, (row) => row.dealValue || 0), [rows]);
    const expectedCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);
    const outstandingCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);

    const activeRows = useMemo(() => rows.filter((row) => row.company?.status === CompanyStatus.active), [rows]);
    const archivedRows = useMemo(() => rows.filter((row) => row.company?.status === CompanyStatus.archived), [rows]);

    const affiliateOptions = useMemo(
        () =>
            sortUsers((affiliateUsersQuery.data || []) as ClearERCUser[]).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliateUsersQuery.data],
    );
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <div
                    style={{ display: "flex", flexDirection: "row", gap: 20, flex: 1, justifyContent: "space-between" }}
                >
                    <Section
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridAutoRows: "70px",
                            rowGap: 0,
                            columnGap: 35,
                        }}
                    >
                        <div style={{ gridRow: 1, gridColumn: "1 / 2" }}>
                            <Stat title="Deals value" value={<Currency amount={deaValueInProgress} />} />
                        </div>
                        <div style={{ gridRow: 1, gridColumn: "2 / 3" }}>
                            <Stat title="Expected total commission" value={<Currency amount={expectedCommission} />} />
                        </div>
                    </Section>
                    <div>
                        <Select
                            label="Viewing deals for affiliate:"
                            options={affiliateOptions}
                            value={affiliateOptions.find((option) => option.value === affiliateUserId)?.value || null}
                            onChange={(value) => setAffiliateUserId(value as string)}
                        />
                    </div>
                </div>
                <h2>Active deals</h2>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Company</Th>
                            <Th>Created</Th>
                            <Th>Owner</Th>
                            <Th>Progress</Th>
                            <Th>Deal value</Th>
                            <Th>Estimated commission</Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {activeRows.length === 0 && (
                            <Tr>
                                <Td colSpan={7} style={{ textAlign: "center" }}>
                                    No active deals
                                </Td>
                            </Tr>
                        )}
                        {activeRows.length > 0 &&
                            activeRows.map((row) => (
                                <Tr key={row.user.id}>
                                    <Td>{row.company?.name || "-"}</Td>
                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <div className="flex flex-col">
                                            {row.company?.createdAt?.toDateString()}
                                            <span style={{ color: "gray" }}>
                                                {row.company?.createdAt
                                                    ? timeAgo(row.company.createdAt).toLocaleString()
                                                    : ""}
                                            </span>
                                        </div>
                                    </Td>
                                    <Td>{[row.user.firstName, row.user.lastName].join(" ") || "-"}</Td>
                                    <Td>{row.progressPercentage}%</Td>
                                    <Td>
                                        <Currency amount={row.dealValue} />
                                    </Td>
                                    <Td>
                                        <Currency amount={row.estimatedCommission} />
                                    </Td>
                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        {row.company?.id && (
                                            <ButtonNeoGen
                                                size="sm"
                                                onClick={() => {
                                                    navigate(`/companies/${row.company?.id}`);
                                                }}
                                            >
                                                Open company
                                            </ButtonNeoGen>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                    </TBody>
                </Table>
                <h2>Archived deals</h2>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Company</Th>
                            <Th>Owner</Th>
                            <Th>Progress</Th>
                            <Th>Deal value</Th>
                            <Th>Estimated commission</Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {archivedRows.length === 0 && (
                            <Tr>
                                <Td colSpan={6} style={{ textAlign: "center" }}>
                                    No archived deals
                                </Td>
                            </Tr>
                        )}
                        {archivedRows.length > 0 &&
                            archivedRows.map((row) => (
                                <Tr key={row.user.id}>
                                    <Td>{row.company?.name || "-"}</Td>
                                    <Td>{[row.user.firstName, row.user.lastName].join(" ") || "-"}</Td>
                                    <Td>{row.progressPercentage}%</Td>
                                    <Td>
                                        <Currency amount={row.dealValue} />
                                    </Td>
                                    <Td>
                                        <Currency amount={row.estimatedCommission} />
                                    </Td>
                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        {row.company?.id && (
                                            <ButtonNeoGen
                                                size="sm"
                                                onClick={() => {
                                                    navigate(`/companies/${row.company?.id}`);
                                                }}
                                            >
                                                Open company
                                            </ButtonNeoGen>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                    </TBody>
                </Table>
            </div>
        </div>
    );
};
