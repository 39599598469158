import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companySchema } from "../domain/company";
import { CompanyFilters } from "../components/modals/company-filters-modal";

export const getCompanies = async ({
    authToken,
    filters,
    adHocFilters,
}: {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
}) => {
    const response = await makeRequest({
        method: "get",
        path: "/companies",
        authToken,
        queryParams: { filter: JSON.stringify(filters), adHocFilters: JSON.stringify(adHocFilters) },
    });

    const companies = parseSchema(z.array(companySchema), response.data);

    return {
        companies,
        hasNextPage: response.headers["x-has-next-page"] === "true",
        totalCount: response.headers["x-total-count"],
    };
};
