import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import Loader2 from "../../sections/utilities/Loader2";
import { getBusinessRuleTypes } from "../business-rule-types/actions/get-business-rule-types";
import { getProcessflowStages } from "../processflow-stages/actions/get-processflow-stages";
import { getProcessflows } from "../processflows/actions/get-process-flows";
import { Entry, ProcessFlow } from "../processflows/domain/processflow";
import { usePromise, usePromiseLazy } from "../shared/hooks";
import { Wizard } from "./wizard";
import { omit, debounce } from "lodash";
import { checkIfProcessflowPasses, validateEntry } from "../processflows/actions/validate-entry";
import { Step } from "../components/steps";
import { BusinessRuleType } from "../business-rule-types/domain/business-rule-type";
import { ButtonActionTypes } from "../../layout/button-creator";
import { updateProcessflowProgress } from "../processflow-progresses/actions/update-processflow-progress";
import { createProcessflowProgress } from "../processflow-progresses/actions/create-processflow-progress";
import userAtom, { assumedUserAtom } from "../../atoms/userAtom";
import { getProcessflowProgresses } from "../processflow-progresses/actions/get-processflow-progresses";
import { useNavigate, useParams } from "react-router";
import { triggerRegistration } from "../users/actions/trigger-registration";
import FakeWizard from "./fake-wizard";
import useQueryParams from "../../hooks/useQueryParams";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";
import { ProcessflowStage } from "../processflow-stages/domain/processflow-stage";
import { sendSlackDebug, sendSlackSale } from "../../services/slack-notifications";
import { z } from "zod";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import utmLogService from "../../services/utm-log.service";
import { UTMLog } from "../../typings/api/utm-logs";
import ErrorSection from "../../layout/error-section";
import Submitting from "./submitting";
import userService from "../../services/user.service";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useBreakpoint } from "../../hooks/appMedia";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getGeoData } from "../other/actions/getMyIp";
import authService from "../../services/auth.service";
import utmLinkService from "../../services/utm-link.service";
import { triggerAffiliateRegistration } from "../users/actions/trigger-affiliate-registration";
import { User } from "../users/domain/user";
import { useQuery } from "@tanstack/react-query";
import roleGroupService from "../../services/role-group.service";
import usersService from "../../services/users.service";
import { useQueryClient } from "@tanstack/react-query";
import { getCompanies } from "../../companies/actions/get-companies";
import { Select } from "../../layout/form/select-input";
import WizardContext from "../../services/wizard-context";
import ButtonNeoGen from "../../layout/button-neogen";
import ModalDialog from "../../layout/modal-dialog";

type Position = {
    processflowStageId: number;
    processflowId: number;
};

export const getDataFromProgressData = (data: ProcessFlowProgressData) => {
    const entryData = data?.entriesByField || {};
    return Object.keys(entryData).reduce((acc: any, key: any) => {
        return { ...acc, ...(!entryData[key].isHidden ? { [key]: entryData[key]?.fieldValue } : {}) };
    }, {});
};

export const WizardContainer = ({
    hideStages,
    hideSteps,
    processflowGroupId,
    user: passedUser,
    onChange,
    companyId: passedCompanyId,
}: {
    hideStages?: boolean;
    hideSteps?: boolean;
    processflowGroupId?: number;
    inIframeParam?: boolean;
    user?: User;
    onChange?: () => void;
    companyId?: number;
}) => {
    const lsCompanyIdString = localStorage.getItem("companyId");
    const lsCompanyId = lsCompanyIdString ? Number(lsCompanyIdString) : undefined;
    const [error, setError] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [entryErrors, setEntryErrors] = useState<Record<string, string[]>>({});
    const [fakeWizardTimeout, setFakeWizardTimeout] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";
    const { processflowGroup, utm } = useParams();
    const group: number = processflowGroupId || (processflowGroup ? Number(processflowGroup) : 7);
    const [companyId, setCompanyId] = useState<number | undefined>(passedCompanyId || lsCompanyId);
    const [processflowId, setProcessflowProgressId] = useState<number | undefined>(undefined);
    const [progressData, setProgressData] = useState<ProcessFlowProgressData | undefined>();
    const [data, setData] = useState<Record<string, any>>(progressData ? getDataFromProgressData(progressData) : {});
    const [position, setPosition] = useState<Position | undefined>(
        progressData?.currentStep && progressData.currentStage
            ? {
                  processflowId: progressData.currentStep,
                  processflowStageId: progressData.currentStage,
              }
            : undefined,
    );

    // TODO: Someone could choose any group id, so we need to validate that the user has access to this group
    const enableValidation = group === 9;
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const [assumedUser] = useRecoilState(assumedUserAtom);
    // TODO: Use proper auth token.
    const authToken = getAuthTokenNoThrow();
    const query = useQueryParams();
    const user = passedUser || assumedUser || loggedInUser;
    const [canSkipStages, setCanSkipStages] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const companiesQuery = useQuery(["companies", { userId: user.id, authToken }], async () => {
        if (authToken) {
            const { companies } = await getCompanies({ authToken });
            return { companies };
        }
        return {};
    });

    const companies = useMemo(() => companiesQuery.data?.companies || [], [companiesQuery.data?.companies]);
    const company = useMemo(
        () => (companyId ? companies.find((c) => c.id === companyId) : undefined),
        [companies, companyId],
    );

    const getPfUserId = useCallback(() => {
        if (group === 7) {
            return company?.ownedById;
        }
        return user.id;
    }, [company?.ownedById, group, user?.id]);

    useEffect(() => {
        if (!passedCompanyId && !lsCompanyId && companies.length > 0) {
            setCompanyId(companies[0].id);
        }
    }, [companies, lsCompanyId, passedCompanyId]);

    useEffect(() => {
        authService.canIAccess("SKIP_STAGES").then((res) => {
            setCanSkipStages(res);
        });
    }, []);

    const inIframe = useCallback(() => {
        try {
            document.body.classList.add("iframe");
            if (query.get("inIframe")) {
                return true;
            }
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, [query]);

    const expectCurrentPosition = useCallback(() => {
        if (!position) {
            throw new Error("Current position not set");
        }
        return position;
    }, [position]);

    useEffect(() => {
        if (inIframe() || query.get("inIframe")) {
            document.body.classList.add("iframe");
            document.body.classList.add("bg-white");
            document.body.classList.remove("bg-slate-300");
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            // setInIframe(true)
        }
    }, [inIframe, query]);

    const updateProgressAction = usePromiseLazy(
        async (d: any, userId: string) => {
            const data = {
                group,
                userId: userId,
                client: 1,
                data: d,
                currentStep: d?.currentStep,
                currentStage: d?.currentStage,
            };

            // TODO: Just get the ID
            const [usersProgress] = await getProcessflowProgresses({
                authToken,
                filters: { userId: userId, group },
            });
            if (usersProgress) {
                await updateProcessflowProgress({
                    authToken,
                    id: usersProgress.id,
                    data,
                });
            } else {
                await createProcessflowProgress({ authToken, data });
            }
            if (onChange) {
                onChange();
            }
        },
        [group],
    );

    const updateProgress = useMemo(
        () =>
            debounce(updateProgressAction.execute, 1500, {
                maxWait: 2000,
                trailing: true,
            }),
        [],
    );

    const processflowStagesAction = usePromise(async () => {
        const processflowStages = await getProcessflowStages({
            authToken,
            processflowGroup: group,
        });
        return processflowStages;
    }, []);
    const processflowStages = useMemo(
        () => (processflowStagesAction.result || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
        [processflowStagesAction.result],
    );
    const currentProcessflowStageIndex = useMemo(
        () => processflowStages.findIndex((stage) => stage.id === position?.processflowStageId),
        [processflowStages, position],
    );

    const handlePositionChange = useCallback(
        (position: Position) => {
            setPosition(position);
            // TODO: This should be stored in the user, not the data
            const positionStageIndex =
                position.processflowStageId !== undefined
                    ? processflowStages.findIndex((pfs) => pfs.id === position.processflowStageId)
                    : undefined;
            const furthestStageIndex =
                progressData?.furthestStageId !== undefined
                    ? processflowStages.findIndex((pfs) => pfs.id === progressData.furthestStageId)
                    : undefined;
            const newFurthestStageId =
                positionStageIndex !== undefined && furthestStageIndex !== undefined
                    ? positionStageIndex > furthestStageIndex
                        ? position.processflowStageId
                        : progressData?.furthestStageId || position.processflowStageId
                    : position.processflowStageId;
            const updatedProgressData = {
                completedSteps: [],
                group,
                entriesByField: {},
                ...progressData,
                currentStage: position.processflowStageId,
                currentStep: position.processflowId,
                furthestStageId: newFurthestStageId,
            };
            setProgressData(updatedProgressData);
            updateProgress(updatedProgressData, getPfUserId());
        },
        [getPfUserId, group, processflowStages, progressData, updateProgress],
    );
    const businessRuleTypesAction = usePromise(async () => {
        const businessRuleTypes = await getBusinessRuleTypes({
            authToken,
        });
        return businessRuleTypes;
    }, []);

    const businessRuleTypes = useMemo(() => businessRuleTypesAction.result || [], [businessRuleTypesAction.result]);

    const processflowsAction = usePromise(async () => {
        const processflows = await getProcessflows({ authToken, group });
        return processflows;
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processflows = useMemo(
        () => (processflowsAction.result || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
        [processflowsAction.result],
    );

    const handleDataChange = useCallback(
        (newData: any, progressData: ProcessFlowProgressData) => {
            setData(newData);
            const nonInteractiveEntryTypes = ["wysiwyg", "textDisplay", "video", "state", "valueCalculator", "button"];
            const allEntries = processflows.reduce<Entry[]>((acc, curr) => {
                return [...acc, ...(curr.entries || [])];
            }, []);
            const allInteractiveEntries = allEntries.filter((entry) => !nonInteractiveEntryTypes.includes(entry.type));
            const relevantInteractiveEntries = allInteractiveEntries.filter(
                (entry) =>
                    !!validateEntry({
                        entry,
                        businessRuleTypes,
                        data: newData,
                    }).passed,
            );
            const completedEntries = allInteractiveEntries.filter((e) => {
                return (
                    !!newData[e.field as any] &&
                    !!validateEntry({
                        entry: e,
                        businessRuleTypes,
                        data: newData,
                    }).passed
                );
            });
            const numberOfRelevantEntries = relevantInteractiveEntries.length;
            const numberOfCompletedEntries = completedEntries.length;
            const percentageComplete = Math.round((numberOfCompletedEntries / numberOfRelevantEntries) * 100) / 100;
            const updatedProgressData = {
                ...progressData,
                numberOfRelevantInteractiveEntries: numberOfRelevantEntries,
                numberOfCompletedEntries: numberOfCompletedEntries,
                percentageComplete,
            };
            setProgressData(updatedProgressData);
            updateProgress(updatedProgressData, getPfUserId());

            // TODO: 9 is Advertising Landing Page and 11 is affiliate training.
            // This is actually a problem because affiliates should be able to save their progress
            // We should only require auth for updates on process flow groups that are not public
            if (group === 7) {
                userService.update(user.id, { pf7percentage: percentageComplete }, true).catch((e) => {
                    console.error(e);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "There was an error saving your progress. Please try again. " + e.message,
                    });
                });
            }
        },
        [businessRuleTypes, getPfUserId, group, processflows, updateProgress, user.id],
    );

    const setInitialPosition = useCallback(() => {
        const stageId = processflowStages[0].id;
        const stageProcessflows = processflows.filter((pf) => pf.stage === stageId);
        const processflowId = stageProcessflows[0].id;
        const newPosition =
            progressData?.currentStage !== undefined &&
            progressData.currentStage !== -1 &&
            progressData.currentStep !== undefined &&
            progressData.currentStep !== -1
                ? {
                      processflowStageId: progressData.currentStage,
                      processflowId: progressData.currentStep,
                  }
                : {
                      processflowStageId: stageId,
                      processflowId: processflowId,
                  };
        setPosition(newPosition);
    }, [processflowStages, processflows, progressData]);

    const getProcessflowsForStageId = useCallback(
        (stageId: number) => {
            const stageProcessflows = processflows.filter((pf) => pf.stage === stageId);
            return stageProcessflows;
        },
        [processflows],
    );

    useEffect(() => {
        const f = async () => {
            const initialStage: ProcessflowStage | undefined = processflowStages[0];
            const processflows = initialStage ? getProcessflowsForStageId(initialStage.id) : [];
            const initialProcessflow = processflows[0];
            if (initialStage && initialProcessflow && group === 7) {
                if (company?.ownedById) {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { userId: company.ownedById, group },
                    });
                    setProcessflowProgressId(usersProgress?.id);
                    setProgressData((usersProgress?.data as any) || undefined);
                    const data = usersProgress?.data ? getDataFromProgressData(usersProgress.data as any) : {};
                    setData(data);
                    setPosition({
                        processflowId: usersProgress?.currentStep || (initialProcessflow.id as any),
                        processflowStageId: usersProgress?.currentStage || (initialStage.id as any),
                    });
                    setHasLoaded(true);
                }
            } else if (initialStage && initialProcessflow && user && group !== 7) {
                const [usersProgress] = await getProcessflowProgresses({
                    authToken,
                    filters: { userId: user.id, group },
                });
                if (usersProgress) {
                    if (usersProgress.data) {
                        setProgressData(usersProgress.data as any);
                    }
                    const data = getDataFromProgressData(usersProgress.data as any);
                    setData(data);
                    setPosition({
                        processflowId: usersProgress.currentStep || (initialProcessflow.id as any),
                        processflowStageId: usersProgress.currentStage || (initialStage.id as any),
                    });
                } else {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { userId: user.id, group: 9 },
                    });

                    const progress = await createProcessflowProgress({
                        authToken,
                        data: {
                            userId: user.id,
                            group,
                            currentStage: initialStage.id,
                            currentStep: initialProcessflow.id,
                            data: usersProgress?.data ?? {
                                completedSteps: [],
                                currentStage: initialStage.id,
                                currentStep: initialProcessflow.id,
                                entriesByField: {},
                            },
                        },
                    });
                    if (progress.data) {
                        setProgressData(progress.data as any);
                    }
                    const data = getDataFromProgressData(usersProgress?.data as any);
                    setData(data);
                    setPosition({
                        processflowId: initialProcessflow.id,
                        processflowStageId: initialStage.id,
                    });
                }
                setHasLoaded(true);
            }
        };
        f();
    }, [
        group,
        user,
        processflows,
        getProcessflowsForStageId,
        assumedUser,
        processflowStages,
        setProgressData,
        authToken,
        company,
        companies,
    ]);

    // Set initial current position on load
    useEffect(() => {
        if (!position && processflowStages[0] && processflows[0]) {
            setInitialPosition();
        }
    }, [processflowStages, processflows, position, setInitialPosition]);

    const getEntriesForProcessFlowId = useCallback(
        ({ processflowId, businessRuleTypes }: { processflowId: number; businessRuleTypes: BusinessRuleType[] }) => {
            const entries: Entry[] = processflows.find((pf) => pf.id === processflowId)?.entries || [];

            const passedEntries = entries.filter((entry: Entry) => {
                return !!validateEntry({
                    entry,
                    data,
                    businessRuleTypes,
                }).passed;
            });

            return passedEntries;
        },
        [data, processflows],
    );

    const handleEntryChange = useCallback(
        ({ entry, value }: { entry: Entry; value: any }) => {
            const updatedData = { ...data, [entry.field]: value };

            if (
                ["Average full time W2 Employees in 2020?", "Average full time W2 Employees in 2021?"].includes(
                    entry.field,
                )
            ) {
                // this is for clearing the cache so that the affiliate stats update
                queryClient.invalidateQueries(["processflowData"]);
            }

            if (enableValidation && submitCount > 0) {
                const currentPosition = expectCurrentPosition();
                const entriesToCheck = businessRuleTypes
                    ? getEntriesForProcessFlowId({
                          processflowId: currentPosition.processflowId,
                          businessRuleTypes: businessRuleTypes,
                      })
                    : [];
                const expectedEntries = entriesToCheck.filter(
                    (entry) =>
                        !!validateEntry({
                            entry,
                            businessRuleTypes,
                            data: updatedData,
                        }).passed,
                );
                const failingEntries = expectedEntries.filter((entry) => {
                    return !!entry.required && !updatedData[entry.field];
                });
                const errors = failingEntries.reduce((acc, curr) => {
                    return { ...acc, [curr.field]: ["Required"] };
                }, {});
                setEntryErrors(errors);
            }

            const processFlow = processflows.find((pf) => pf.id === position?.processflowId);
            const entries = processFlow?.entries || [];
            const currentEntryIndex = entries.findIndex((e) => e.id === entry.id);
            const followingEntries = entries.filter((entry, index) => {
                return index > currentEntryIndex;
            });

            let t = updatedData;

            const entriesToReset = entries.filter((entry) => {
                const isValid = validateEntry({
                    entry,
                    businessRuleTypes,
                    data: t,
                }).passed;
                if (!isValid) {
                    t = omit(t, entry.field);
                }
                return !isValid;
            });
            const updatedDataWithFollowingEntriesReset: any = omit(
                updatedData,
                entriesToReset.map((entry) => entry.field),
            );

            const updatedProgressData: ProcessFlowProgressData = {
                group,
                completedSteps: [],
                ...progressData,
                entriesByField: Object.keys(updatedData).reduce((acc, fieldKey: string) => {
                    return {
                        ...acc,
                        [fieldKey]: {
                            fieldName: fieldKey,
                            fieldValue: updatedData[fieldKey],
                            // Hide fields which will be reset based on current entry change.
                            isHidden: entriesToReset.map((entry) => entry.field).includes(fieldKey),
                        },
                    };
                }, progressData?.entriesByField || {}),
            };

            // Traverse following entries, if entry is valid and is hidden, then add its value back into the 'data' object from 'progressData' because it should now be displayed.
            const dataFromPreviouslyHiddenEntries = followingEntries.reduce((acc, entry) => {
                const fieldData = updatedProgressData.entriesByField[entry.field];
                const isHidden = fieldData?.isHidden;
                const validationResult = validateEntry({
                    entry,
                    businessRuleTypes,
                    data: { ...acc },
                });
                const isValid = validationResult.passed;
                return isValid && isHidden ? { ...acc, [entry.field]: fieldData.fieldValue } : omit(acc, entry.field);
            }, updatedDataWithFollowingEntriesReset);

            const finalData = { ...updatedDataWithFollowingEntriesReset, ...dataFromPreviouslyHiddenEntries };

            const finalProgressData: ProcessFlowProgressData = {
                ...updatedProgressData,
                entriesByField: Object.keys(finalData).reduce((acc, fieldKey: string) => {
                    const entry = entries.find((e) => e.field === fieldKey);
                    const isValid =
                        entry &&
                        validateEntry({
                            entry,
                            businessRuleTypes,
                            data: finalData,
                        }).passed;
                    return {
                        ...acc,
                        [fieldKey]: {
                            fieldName: fieldKey,
                            fieldValue: finalData[fieldKey],
                            // Reset isHidden to false if the entry is now being shown.
                            isHidden: isValid ? false : !finalData[fieldKey],
                        },
                    };
                }, updatedProgressData.entriesByField),
            };

            handleDataChange(finalData, finalProgressData);
        },
        [
            data,
            enableValidation,
            submitCount,
            processflows,
            group,
            progressData,
            handleDataChange,
            queryClient,
            expectCurrentPosition,
            businessRuleTypes,
            getEntriesForProcessFlowId,
            position?.processflowId,
        ],
    );

    const doesProcessflowPass = useCallback(
        (pf: ProcessFlow, data: Record<string, any>) => {
            return checkIfProcessflowPasses({
                processflow: pf as any,
                progressData: data,
                businessRuleGroups: pf.businessRuleGroups as any,
                businessRuleTypes,
            }).passed;
        },
        [businessRuleTypes],
    );

    const getStepsForStageId = useCallback(
        (stageId: number) => {
            const currentPosition = expectCurrentPosition();
            const stageProcessflows = getProcessflowsForStageId(stageId);
            const currentStepIndex = stageProcessflows.findIndex((pf) => pf.id === currentPosition.processflowId);
            const steps: Step[] = stageProcessflows.map((pf, stepIndex) => ({
                id: pf.id,
                name: pf.title,
                hideIcon: isMobile || isTablet,
                status:
                    currentPosition.processflowId === pf.id
                        ? "current"
                        : stepIndex < currentStepIndex
                        ? "complete"
                        : "upcoming",
                action: () => {
                    handlePositionChange({
                        ...currentPosition,
                        processflowId: pf.id,
                    });
                },
            }));
            return steps.filter((step: Step) => {
                const pf = processflows.find((pf) => pf.id === step.id);
                if (!pf) {
                    throw new Error("processflow not found");
                }
                return doesProcessflowPass(pf as any, data);
            });
        },
        [
            data,
            doesProcessflowPass,
            expectCurrentPosition,
            getProcessflowsForStageId,
            handlePositionChange,
            isMobile,
            isTablet,
            processflows,
        ],
    );

    const handleButtonClick = useCallback(
        async (action: ButtonActionTypes) => {
            const emailField = "Email "; // Trailing space is intentional.
            setEntryErrors({});
            setSubmitCount(submitCount + 1);
            const currentPosition = expectCurrentPosition();
            const processflowsForCurrentStage = getProcessflowsForStageId(currentPosition.processflowStageId);
            if (enableValidation) {
                const entries = businessRuleTypes
                    ? getEntriesForProcessFlowId({
                          processflowId: currentPosition.processflowId,
                          businessRuleTypes: businessRuleTypes,
                      })
                    : [];
                const expectedEntries = entries.filter(
                    (entry) => !!validateEntry({ entry, businessRuleTypes, data }).passed,
                );
                const failingEntries = expectedEntries.filter((entry) => {
                    return !!entry.required && !data[entry.field];
                });
                const errors = failingEntries.reduce((acc, curr) => {
                    return { ...acc, [curr.field]: ["Required"] };
                }, {});
                if (Object.keys(errors).length > 0) {
                    setEntryErrors(errors);
                    return;
                }
            }
            const actionMap: Record<string, () => void> = {
                nextStep: () => {
                    const processflows = processflowsForCurrentStage.filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    const currentProcessflowIndex = processflows.findIndex(
                        (pf) => pf.id === currentPosition.processflowId,
                    );
                    const nextProcessflow = processflows[currentProcessflowIndex + 1];
                    if (nextProcessflow) {
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        });
                    } else {
                        const nextStage = processflowStages[currentProcessflowStageIndex + 1];
                        const pfs = getProcessflowsForStageId(nextStage.id).filter((pf) =>
                            doesProcessflowPass(pf as any, data),
                        );
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: pfs[0].id,
                            processflowStageId: nextStage.id,
                        });
                    }
                    setSubmitCount(0);
                },
                previousStep: () => {
                    const passingProcessflows = processflows.filter((pf) => doesProcessflowPass(pf as any, data));
                    const currentProcessflowIndex = passingProcessflows.findIndex(
                        (pf) => pf.id === currentPosition.processflowId,
                    );
                    const previousProcessflow = passingProcessflows[currentProcessflowIndex - 1];
                    handlePositionChange({
                        ...currentPosition,
                        // Manually update stage to handle cases where previous step is from a previous stage.
                        processflowStageId: previousProcessflow.stage,
                        processflowId: previousProcessflow.id,
                    });
                    setSubmitCount(0);
                },
                nextStage: () => {
                    const nextStage = processflowStages[currentProcessflowStageIndex + 1];
                    const processflows = getProcessflowsForStageId(nextStage.id).filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    handlePositionChange({
                        processflowStageId: nextStage.id,
                        processflowId: processflows[0].id,
                    });
                    setSubmitCount(0);
                },
                previousStage: () => {
                    const previousStage = processflowStages[currentProcessflowStageIndex - 1];
                    const processflows = getProcessflowsForStageId(previousStage.id).filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    handlePositionChange({
                        processflowStageId: previousStage.id,
                        processflowId: processflows[processflows.length - 1].id,
                    });
                    setSubmitCount(0);
                },
                completeAffiliateTraining: async () => {
                    if (user.id) {
                        await roleGroupService.postURL("role-group-users", {
                            clearErcUserId: user.id,
                            roleGroupsId: 7, // set to affiliate role group
                        });

                        const r = await roleGroupService.getURL(
                            "role-group-users?filter=" +
                                JSON.stringify({
                                    where: {
                                        clearErcUserId: user.id,
                                        roleGroupsId: 12, // find affiliate in training entries
                                    },
                                }),
                        );

                        // Delete any affiliate in training entries for the user
                        if (r?.data && r.data?.length > 0) {
                            await Promise.all(
                                r.data.map((item: any) => roleGroupService.deleteURL(`role-group-users/${item.id}`)),
                            );
                        }

                        const response = await usersService.getOne(user.id as string);
                        const updatedUser = response?.data as ClearERCUser;
                        setUser(updatedUser as ClearERCUser);
                        authService.user = updatedUser;
                        localStorage.setItem("user", JSON.stringify({ ...updatedUser, token: authToken }));
                        const rbac_response = await authService.API.getURL(`users/${user.id}/roles`);
                        authService.roles = rbac_response?.data;
                        authService.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out
                        authService.roles.push({ roleCode: "URL_DASHBOARD" }); // Everyone can log out

                        window.location.href = "/dashboard";
                    }
                },
                redirect: () => {
                    const url = JSON.parse(localStorage.getItem("button") || "")?.urlToRedirectTo;
                    if (url) {
                        navigate(url);
                    }
                },
                createAccountSendConfirmation: async () => {
                    // This is where email should come from:
                    // const button = localStorage.getItem("button")
                    // console.error("button");
                    if (!user) {
                        console.error("User not defined");
                        throw new Error("User not defined");
                    }
                    if (!user.id) {
                        console.error("User id not defined");
                        throw new Error("User id not defined");
                    }
                    // TODO: these should not be hardcoded - they should come from the button object above
                    const email = data[emailField];
                    // console.error({email});
                    try {
                        z.string().email().parse(email);
                    } catch (e) {
                        setEntryErrors({
                            ...entryErrors,
                            [emailField]: ["Invalid email address"],
                        });
                        throw new Error("Please enter a valid email address.");
                    }

                    let affiliateUserId = "";
                    if (utm) {
                        // alert(1)
                        try {
                            // Get the affiliate associated with the UTM code
                            const response = await utmLinkService.getFilteredWhere({ code: utm });
                            if (response) {
                                const affiliate = response.data[0].owner;
                                // alert(affiliate)
                                if (affiliate) {
                                    // Associate the affiliate with the user
                                    // await affiliateService.update(user.id, { affiliate });
                                    affiliateUserId = affiliate;
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                    // TODO: these should not be hardcoded
                    const newUserData = {
                        id: user.id,
                        email,
                        name: data["Company name"],
                        firstName: data["First name"],
                        lastName: data["Last name"],
                        company: data["Company name"],
                        phoneNumber: data["Phone number"],
                        utm: utm,
                        affiliateUserId,
                        defaultProcessflowId: 7,
                    };

                    try {
                        setSubmitting(true);
                        const currentProcessflowIndex = processflowsForCurrentStage.findIndex(
                            (pf) => pf.id === currentPosition.processflowId,
                        );
                        let newUser;
                        if (group === 9) {
                            newUser = await triggerRegistration({
                                userId: newUserData.id,
                                email: newUserData.email,
                                name: newUserData.name,
                                firstName: newUserData.firstName,
                                lastName: newUserData.lastName,
                                phoneNumber: newUserData.phoneNumber,
                                utm: utm ?? "",
                                affiliateUserId: affiliateUserId ?? "",
                                defaultProcessflowId: 7,
                            });
                        } else if (group === 14) {
                            newUser = await triggerAffiliateRegistration({
                                userId: newUserData.id,
                                email: newUserData.email,
                                firstName: newUserData.firstName,
                                name: newUserData.name,
                                lastName: newUserData.lastName,
                                phoneNumber: newUserData.phoneNumber,
                                utm: utm ?? "",
                                affiliateUserId: affiliateUserId ?? "",
                                defaultProcessflowId: 11,
                            });
                        }
                        const updatedUser = { ...user, ...newUser };

                        const handleLogging = async () => {
                            let geoData: Record<string, any> = {};
                            try {
                                // This request is sometimes blocked by ad-blockers (AdBlock, uBlock etc)
                                geoData = await getGeoData();
                            } catch (error) {
                                console.error("Failed to get geolocation data.", error);
                            }
                            try {
                                // const getData = async () => {
                                // const geo = await axios.get("https://geolocation-db.com/json/");
                                // return (res.data);
                                // };
                                // const geoData = geo.data;
                                // sendSlackDebug(
                                //     JSON.stringify({ newUser: newUserData, utm }),
                                // )
                                // sendSlackSale(
                                //     JSON.stringify({ newUser: newUserData, utm }),
                                // )
                                if (currentProcessflowIndex === 14) {
                                    sendSlackDebug(
                                        `Affiliate UTM Registration: ${utm}\nCountry:  ${
                                            geoData?.country_name
                                        }\nCity: ${geoData?.city}\nState: ${geoData?.state}\nIP: ${
                                            geoData?.IPv4
                                        }\nURL: ${window.location}\nReferrer: ${
                                            document.referrer
                                        }\nnewUser: ${JSON.stringify(newUserData)}`,
                                    );
                                } else {
                                    sendSlackDebug(
                                        `UTM Registration: ${utm}\nCountry:  ${geoData?.country_name}\nCity: ${
                                            geoData?.city
                                        }\nState: ${geoData?.state}\nIP: ${geoData?.IPv4}\nURL: ${
                                            window.location
                                        }\nReferrer: ${document.referrer}\nnewUser: ${JSON.stringify(newUserData)}`,
                                    );
                                }
                                sendSlackSale(
                                    `UTM Registration: ${utm}\nCountry:  ${geoData?.country_name}\nCity: ${
                                        geoData?.city
                                    }\nState: ${geoData?.state}\nIP: ${geoData?.IPv4}\nURL: ${
                                        window.location
                                    }\nReferrer: ${document.referrer}\nnewUser: ${JSON.stringify(newUserData)}`,
                                );

                                const ip = geoData?.IPv4 ?? "Unknown";
                                const utmLog: UTMLog = {
                                    utm: utm ?? "Unknown",
                                    ipAddress: ip,
                                    data: JSON.stringify(newUserData),
                                    eventDatetime: new Date(),
                                    userId: updatedUser.id as string,
                                    site: "",
                                    notes: `UTM Registration: ${utm}\nCountry:  ${geoData?.country_name}\nCity: ${
                                        geoData?.city
                                    }\nState: ${geoData?.state}\nIP: ${geoData?.IPv4}\nURL: ${
                                        window.location
                                    }\nReferrer: ${document.referrer}\nnewUser: ${JSON.stringify(newUserData)}`,
                                };
                                utmLogService.create(utmLog);
                            } catch (e) {
                                console.error(e);
                            }
                        };

                        // Not awaited to prevent slowdowns
                        handleLogging();

                        const nextProcessflow = processflowsForCurrentStage[currentProcessflowIndex + 1];
                        const newPosition = {
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        };
                        const nextProcessflowId = nextProcessflow.id;
                        const [usersProgress] = await getProcessflowProgresses({
                            authToken,
                            filters: { userId: user.id, group },
                        });
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflowId,
                        });

                        if (usersProgress) {
                            const updatedProgressData = {
                                ...progressData,
                                currentStage: newPosition.processflowStageId,
                                currentStep: newPosition.processflowId,
                            };
                            await updateProgressAction.execute(updatedProgressData, updatedUser.id);
                        }

                        setUser(updatedUser as ClearERCUser);
                        localStorage.setItem("user", JSON.stringify(updatedUser));
                        setSubmitCount(0);
                    } catch (e) {
                        console.error(JSON.stringify((e as Error).message));
                        sendSlackDebug(
                            JSON.stringify({
                                error: (e as Error).toString(),
                                newUser: newUserData,
                                utm,
                            }),
                        );
                        throw e;
                    }
                    setSubmitting(false);
                },
            };

            const f = actionMap[action];

            if (!f) {
                throw new Error(`No button action defined for '${action}'`);
            }

            setError(undefined);
            try {
                await f();
            } catch (error) {
                console.error(error);
                if ((error as Error).message.includes("ER_DUP_ENTRY")) {
                    (error as Error).message =
                        "An account with this email address already exists. Please login or reset your password.";
                }
                setError(error);
                setEntryErrors({
                    ...entryErrors,
                    [emailField]: ["Invalid email address"],
                });
            }
            setSubmitting(false);
        },
        [
            authToken,
            businessRuleTypes,
            currentProcessflowStageIndex,
            data,
            doesProcessflowPass,
            enableValidation,
            entryErrors,
            expectCurrentPosition,
            getEntriesForProcessFlowId,
            getProcessflowsForStageId,
            group,
            handlePositionChange,
            navigate,
            processflowStages,
            processflows,
            progressData,
            setUser,
            submitCount,
            updateProgressAction,
            user,
            utm,
        ],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setFakeWizardTimeout(true);
        }, 1);
        return () => clearTimeout(timer);
    }, []);

    const steps = position ? getStepsForStageId(position.processflowStageId) : undefined;

    const entries =
        position && businessRuleTypes
            ? getEntriesForProcessFlowId({
                  processflowId: position.processflowId,
                  businessRuleTypes: businessRuleTypes,
              })
            : undefined;

    const stages = processflowStages.length > 0 ? processflowStages : undefined;

    const allSteps = useMemo(() => {
        let allSteps: any[] = [];

        stages?.forEach((stage) => {
            const steps = getProcessflowsForStageId(stage.id);
            const filteredSteps = steps.filter((step) => doesProcessflowPass(step as any, data));
            allSteps = [...allSteps, ...filteredSteps];
        });

        return allSteps;
    }, [data, doesProcessflowPass, getProcessflowsForStageId, stages]);

    const isLoading = !steps || !entries || !stages;

    const isReady =
        hasLoaded && steps && steps.length > 0 && entries && entries.length > 0 && stages && stages.length > 0;

    return !isReady || isLoading || !position || !fakeWizardTimeout ? (
        <>{hideStages && hideSteps ? <FakeWizard /> : <Loader2 />}</>
    ) : (
        <>
            {!passedCompanyId && companies.length > 1 && (
                <>
                    <div className="bg-white rounded-lg p-3 text-center dark:bg-slate-800 pl-24">
                        {company?.name?.split(" ").map((word, idx) => (
                            <span key={idx}>
                                <span className="each-word mx-auto text-3xl font-medium uppercase antialiased text-indigo-700 dark:text-green-500 tracking-widest">
                                    {word.substring(0, 1)}
                                </span>
                                <span className="each-word mx-auto text-2xl font-medium uppercase antialiased text-indigo-700 dark:text-green-500 tracking-widest mr-5">
                                    {word.substring(1)}
                                </span>
                            </span>
                        ))}
                        <ButtonNeoGen
                            className="align-top mt-2"
                            size="xxs"
                            type="outline"
                            onClick={() => {
                                setShowModal(true);
                                // setPassedCompanyId(true);
                            }}
                        >
                            Change Company
                        </ButtonNeoGen>
                    </div>
                    <div className="pb-4 mt-0 w-auto sm:w-64 md:w-96 overflow-none "></div>
                    <ModalDialog
                        show={showModal}
                        close={() => {
                            setShowModal(false);
                        }}
                        showOk={false}
                        title="Change Company"
                        // className="w-full sm:w-96"
                    >
                        <div className="mb-5">
                            <Select
                                isClearable={false}
                                value={company?.id || null}
                                onChange={(id: any) => {
                                    setCompanyId(id);
                                    localStorage.setItem("companyId", id);
                                    setShowModal(false);
                                }}
                                options={companies.map((company) => ({
                                    value: company.id,
                                    label: company.name || "No name",
                                }))}
                            />
                        </div>
                    </ModalDialog>
                </>
            )}
            {/* Show header if iFrame and not on Congratulations, you’re pre-qualified stage */}
            {inIframe() &&
                !(
                    expectCurrentPosition().processflowId === 80 && expectCurrentPosition().processflowStageId === 33
                ) && (
                    <div className="text-center ">
                        <p className="text-indigo-600 font-extrabold text-4xl tracking-wider w-full h-15 titling-caps">
                            See How Much You Could Get
                        </p>
                    </div>
                )}
            {error && (
                <>
                    <ErrorSection errors={[error.message]} />
                </>
            )}
            {/* eslint-disable-next-line no-constant-condition */}
            {submitting ? (
                <Submitting />
            ) : (
                <WizardContext.Provider
                    value={{
                        companyId: company?.id,
                        processflowProgressId: processflowId,
                    }}
                >
                    <Wizard
                        allSteps={allSteps}
                        stages={stages.map((stage, index) => {
                            const furthestStageId = progressData?.furthestStageId;
                            const furthestStageIndex = stages.findIndex((stage) => stage.id === furthestStageId);
                            const isPastStage = index <= furthestStageIndex;
                            return {
                                ...stage,
                                onClick: isPastStage
                                    ? (id) => {
                                          const steps = getStepsForStageId(id);
                                          handlePositionChange({
                                              processflowStageId: id,
                                              processflowId: steps[0].id,
                                          });
                                      }
                                    : undefined,
                            };
                        })}
                        group={group}
                        stageId={position.processflowStageId}
                        onStageChange={
                            canSkipStages
                                ? (stage) => {
                                      const steps = getStepsForStageId(stage.id);
                                      handlePositionChange({
                                          processflowStageId: stage.id,
                                          processflowId: steps[0].id,
                                      });
                                  }
                                : undefined
                        }
                        steps={steps}
                        stepId={position.processflowId}
                        entries={entries}
                        entryErrors={entryErrors}
                        onEntryChange={({ entry, value }) => {
                            handleEntryChange({ entry, value });
                        }}
                        data={data}
                        onButtonClick={({ action }) => {
                            handleButtonClick(action);
                        }}
                        hideStages={hideStages}
                        hideSteps={hideSteps}
                        getFileKey={(entry: Entry) =>
                            JSON.stringify({ userId: user.id, group, entryField: entry.field })
                        }
                    />
                    {group === 9 && progressData?.currentStep === 79 && (
                        <div className="text-sm pt-3">
                            <Link to="/login" className="text-gray-700 hover:text-indigo-500">
                                Already have an account? Sign in
                            </Link>
                        </div>
                    )}
                </WizardContext.Provider>
            )}
        </>
    );
};
