import { useState, useEffect, useCallback } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import PageDescription from "../../../layout/page-description";
import SearchField from "../../../layout/search-field";
import TableNeogen from "../../../layout/table-neogen";
import Main from "../../../layout/Main";
import { usePaginatedResultItems } from "../../../hooks/usePaginatedResults";
import { PaginatedView } from "../../../layout/PaginatedView";
import { CreateClientMagicLinkModal } from "../../../magic-links/components/create-client-magic-link-modal";
import authService from "../../../services/auth.service";
import magicLinksService from "../../../services/magic-links.service";
import { MagicLink } from "../../../magic-links/domain/magic-link";
import { EditClientMagicLinkModal } from "../../../magic-links/components/edit-client-magic-link-modal";
// import Swal from "sweetalert2";
import { Toast } from "../../utilities/toast";
import SwalNeogenFire from "../../../layout/swal-neogen";
import { fetchItems } from "../../../jason-proof-of-concept/shared/utils";

type ModalType = "create" | "edit" | "none";

interface MagicLinksProps {
    itemsPerPage?: number;
}

export function getDarkMode(): boolean {
    const currentLocalStorageTheme = localStorage.getItem("theme");
    if (currentLocalStorageTheme !== null) {
        if (currentLocalStorageTheme === "dark") {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");

            return true;
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            return false;
        }
    } else {
        // Couldn't find theme in local storage
        // console.error("Theme not stored");
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");
            localStorage.setItem("theme", "dark");
            return true;
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            localStorage.setItem("theme", "light");
            return false;
        }
    }
}

export default function MagicLinks({ itemsPerPage = 100 }: MagicLinksProps) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [canIAccess, setCanIAccess] = useState(false);
    const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);
    const [modalType, setModalType] = useState<ModalType>("none");
    const [toast, setToast] = useState({
        show: false,
        message: "",
    });
    const [defaultValues, setDefaultValues] = useState<Partial<MagicLink>>({});

    type QueryKey = ["magic-links", string];

    const fetchMagicLinks = async ({
        pageParam,
        queryKey,
    }: {
        pageParam?: { hasNextPage: boolean; startAt?: number };
        queryKey: QueryKey;
    }) => {
        return fetchItems({
            pageParam,
            queryKey,
            serviceFunction: magicLinksService.getMagicLinks,
            itemsPerPage: itemsPerPage, // Define itemsPerPage as needed
        });
    };

    const { data, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery(
        ["magic-links", debouncedSearch], // Add the searchQuery parameter to the query key
        fetchMagicLinks,
        {
            refetchOnMount: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                const hasNextPage = lastPage?.hasNextPage || null;
                return { hasNextPage, startAt: lastPage?.startAt };
            },
        },
    );

    const magicLinkItems = usePaginatedResultItems<
        {
            pages: MagicLink[];
            hasNextPage: boolean;
        },
        MagicLink
    >(data, (response) => response.pages);
    const processedMagicLinks = magicLinkItems.map((magicLink) => ({
        ...magicLink,
        createdBy: [magicLink.createdByUser?.firstName, magicLink.createdByUser?.lastName].join(" "),
        createdFor: [magicLink.data.firstName, magicLink.data.lastName].join(" "),
    }));

    const magicLinks = (processedMagicLinks || []) as MagicLink[];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    useEffect(() => {
        authService.canIAccess("ADD_USER").then((response) => {
            setCanIAccess(response);
        });
    }, []);

    const onClose = () => {
        setShowMagicLinkModal(false);
        setDefaultValues({} as MagicLink);
        setModalType("none");
    };

    return (
        <Main>
            {toast.show && (
                <Toast
                    position="bottomRight"
                    message={toast.message}
                    onClose={() => {
                        setShowMagicLinkModal(false);
                        setDefaultValues({} as MagicLink);
                        setToast({ show: false, message: "" });
                    }}
                />
            )}
            {showMagicLinkModal && modalType === "create" && (
                <CreateClientMagicLinkModal
                    onClose={onClose}
                    onMagicLinkCreated={() => {
                        refetch();
                    }}
                />
            )}
            {showMagicLinkModal && modalType === "edit" && defaultValues && (
                <EditClientMagicLinkModal
                    onClose={onClose}
                    defaultValues={defaultValues as any}
                    onMagicLinkUpdated={() => {
                        refetch();
                    }}
                />
            )}
            <PageDescription
                title="Magic links"
                description="Manage magic links"
                buttons={[
                    {
                        label: "Create Magic Link",
                        onClick: () => {
                            setModalType("create");
                            setShowMagicLinkModal(true);
                        },
                        disabled: !canIAccess,
                        icon: "fas fa-user-plus",
                    },
                ]}
            >
                <SearchField search={search} setSearch={setSearch} placeholder="Search magic links..." />
            </PageDescription>
            {magicLinks && (
                <PaginatedView
                    onRequestToLoadMore={fetchNextPage}
                    isLoading={isFetchingNextPage}
                    currentCount={magicLinkItems.length}
                    // pages[0] because all pages have the same `total`
                    totalCount={data?.pages[0]?.total || 0}
                >
                    <TableNeogen
                        entries={magicLinks.map((ml) => {
                            return {
                                id: ml.id,
                                createdAt: ml.createdAt || "-",
                                type: ml.type,
                                code: ml.code,
                                createdFor: [ml.data.firstName, ml.data.lastName].join(" "),
                                email: ml.data.email,
                                createdBy: [ml.createdByUser?.firstName, ml.createdByUser?.lastName].join(" "),
                                usedAt: ml.usedAt ? String(ml.usedAt) : "-",
                            };
                        })}
                        actionsAreDropDown
                        actions={[
                            {
                                label: "Edit",
                                onClick: (magicLink: string) => {
                                    const ml = magicLinks.find((ml) => ml.id === magicLink);
                                    setDefaultValues({
                                        ...ml,
                                        options: {
                                            affiliateUserId: ml?.options?.affiliateUserId,
                                            docCollectorId: ml?.options?.docCollectorId,
                                        },
                                    });
                                    setShowMagicLinkModal(true);
                                    setModalType("edit");
                                },
                                disabled: (magicLinkId: string) => {
                                    const magicLink = magicLinks.find((ml) => ml.id === magicLinkId);
                                    return !!(magicLink && magicLink.usedAt);
                                },
                            },
                            {
                                label: "Resend link",
                                onClick: (magicLink: string) => {
                                    SwalNeogenFire({
                                        darkMode: getDarkMode(),
                                        title: "Resend Magic Link",
                                        text: "Are you sure you want to resend this magic link?",
                                        icon: "info",
                                        showCancelButton: true,
                                        // confirmButtonColor: "#3085d6",
                                        // cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, resend it!",
                                        cancelButtonText: "No, cancel!",
                                        // background: "#ff0000",
                                        showLoaderOnConfirm: true,
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            const resendResponse = await magicLinksService.resendMagicLink(magicLink);
                                            if (resendResponse.status === 200) {
                                                setToast({
                                                    show: true,
                                                    message: resendResponse.data,
                                                });
                                            }
                                        }
                                    });
                                },
                                disabled: (magicLinkId: string) => {
                                    const magicLink = magicLinks.find((ml) => ml.id === magicLinkId);
                                    return !!(magicLink && magicLink.usedAt);
                                },
                            },
                        ]}
                        formatters={[
                            {
                                field: "createdByUser",
                                type: "DisplayItem",
                                itemToDisplay: "createdByUser.name",
                            },
                            {
                                field: "usedAt",
                                type: "DateTime",
                            },
                        ]}
                    />
                </PaginatedView>
            )}
        </Main>
    );
}
